import React, { useEffect, useState, useContext } from "react";
import apiDataVisualization from "../services/apiDataVisualization";
import { DASHBOARD_BASE_URL } from "../constant";
import { exportToExcel } from "../utils";
import DateSelector from "../components/DateSelector";
import toast from "react-hot-toast";
import Modal from "../components/Modal";
import TableData from "../components/TableData";
import TableNameDropdown from "../components/SelectGroup/TableNameDropdown";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { AuthContext } from "../components/PrivateRoutes";
import { useTranslation } from "react-i18next";

const DataVisualization = () => {
  const [tableNames, setTableNames] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submit, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, token } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTableNames = async () => {
      try {
        const response = await apiDataVisualization.get(
          `${DASHBOARD_BASE_URL}/api/table-names`,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setTableNames(response.data);
      } catch (err) {
        console.error("Error fetching table names:", err);
        toast.error("Error fetching table names");
      }
    };

    fetchTableNames();
  }, []);

  useEffect(() => {
    if (selectedTableName) {
      const fetchDates = async () => {
        setLoading(true);
        try {
          const response = await apiDataVisualization.get(
            `${DASHBOARD_BASE_URL}/api/date/${selectedTableName}`,
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
          setDates(response.data);
        } catch (err) {
          console.error("Error fetching dates:", err);
          toast.error("Error fetching dates");
        }
        setLoading(false);
      };

      fetchDates();
    }
  }, [selectedTableName]);

  const handleTableChange = (event) => {
    setSelectedTableName(event.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const getSalesData = async (interval) => {
    try {
      
      const response = await apiDataVisualization.get(
        `${DASHBOARD_BASE_URL}/api/get-sales-data/${interval}`,
        {
          headers: {
            "x-access-token": token,
          },
        })
        return response.data
    }catch(err) {
      console.error("Error fetching table data:", err);
      toast.error("Error fetching sales data");
    }
  }

  const getDentalUnitsData = async () => {
    try {
      const response = await apiDataVisualization.get(
        `${DASHBOARD_BASE_URL}/api/get-dental-units`,
        {
          headers: {
            "x-access-token": token,
          },
        })
        return response.data
    }catch(err) {
      console.error("Error fetching Dental Units:", err);
      toast.error("Error fetching Dental Units");
    }
  }

  const getDentalUnitsPerformanceData = async (date) => {
    try {
      const response = await apiDataVisualization.post(
        `${DASHBOARD_BASE_URL}/api/get-dental-units-performance`,{
          date
        },
        {
          headers: {
            "x-access-token": token,
          },
        })
        return response.data
    }catch(err) {
      console.error("Error fetching Dental Units Performance:", err);
      toast.error("Error fetching Dental Units Performance");
    }
  }


  const getFoxiData = async (date) => {
    try {
      const response = await apiDataVisualization.post(
        `${DASHBOARD_BASE_URL}/api/get-foxi-data`,{
          date
        },
        {
          headers: {
            "x-access-token": token,
          },
        })
        return response.data
    }catch(err) {
      console.error("Error fetching Dental Units Performance:", err);
      toast.error("Error fetching Dental Units Performance");
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await apiDataVisualization.post(
        `${DASHBOARD_BASE_URL}/api/get-data`,
        {
          tableName: selectedTableName,
          date: selectedDate,
          location: user.location,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      const salesDataOf7Days = await getSalesData('7d')
      const salesDataOf13Weeks = await getSalesData('3w')
      const dentalUnits = await getDentalUnitsData()
      const dentalUnitPerformance = await getDentalUnitsPerformanceData(selectedDate)
      const foxi = await getFoxiData(selectedDate)
      setTableData([...response.data,{'Foxi': foxi}, {"Leistung_letzte_Woche": salesDataOf7Days}, {"Leistung_letzte_13_Kalenderwochen": salesDataOf13Weeks}, {"Dentaleinheiten": dentalUnits}, {"Performance_per_dental_unit": dentalUnitPerformance}]);
      setIsModalOpen(true);
    } catch (err) {
      console.error("Error fetching table data:", err);
      toast.error("Error fetching table data");
    }
    setSubmitting(false);
  };

  const handleExportNoFollowupTreatment = async (setExporting) => {
    try {
      setExporting(true);
      const response = await apiDataVisualization.post(
        `${DASHBOARD_BASE_URL}/api/get-table`,
        {
          tableName: `${selectedTableName}_No_FollowUp_Treatment`,
          date: selectedDate,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      const rawData = JSON.parse(response.data);
      const zip = new JSZip();
      Object.keys(rawData).forEach(function (key) {
        exportToExcel(rawData[key], key, zip);
      });
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${selectedTableName}_No_FollowUp_Treatment_${selectedDate}.zip`);
      });
    } catch (err) {
      console.error("Error exporting data:", err);
      toast.error("Error exporting data");
    }
    setExporting(false);
  };

  const handleExportNoDocumentation = async (setExporting) => {
    setExporting(true);
    try {
      const response = await apiDataVisualization.post(
        `${DASHBOARD_BASE_URL}/api/get-table`,
        {
          tableName: `Ftp_${selectedTableName}_PatientId_With_Missing_Document`,
          date: selectedDate,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      const data = JSON.parse(response.data);
      const mainZip = new JSZip();
  
      // Process each location's data
      for (const [location, items] of Object.entries(data)) {
        for (const item of items) {
          if (item.fileData && item.fileName) {
            try {
              // Convert base64 to binary
              const byteCharacters = atob(item.fileData);
              const byteNumbers = new Array(byteCharacters.length);
              
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              
              const byteArray = new Uint8Array(byteNumbers);
              
              // Add to zip without additional processing
              mainZip.file(`${location}/${item.fileName}`, byteArray, {
                binary: true
              });
            } catch (error) {
              console.error(`Error processing file for ${location}:`, error);
              toast.error(`Error processing file for ${location}`);
            }
          }
        }
      }
  
      // Generate and download the final zip file
      const content = await mainZip.generateAsync({ 
        type: "blob"
      });
  
      saveAs(content, `${selectedTableName}_PatientId_With_Missing_Document_${selectedDate}.zip`);
  
    } catch (err) {
      console.error("Error exporting data:", err);
      toast.error("Error exporting data");
    }
    setExporting(false);
  };

  const handleExportPatientWithNoService = async (setExporting) => {
    setExporting(true);
    try {

      const response = await apiDataVisualization.post(
        `${DASHBOARD_BASE_URL}/api/get-table`,
        {
          tableName: `Ftp_${selectedTableName}_Patienten_ohne_Leistung`,
          date: selectedDate,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      const data = JSON.parse(response.data);
      const mainZip = new JSZip();
  
      // Process each location's data
      for (const [location, items] of Object.entries(data)) {
        for (const item of items) {
          if (item.fileData && item.fileName) {
            try {
              // Convert base64 to binary
              const byteCharacters = atob(item.fileData);
              const byteNumbers = new Array(byteCharacters.length);
              
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              
              const byteArray = new Uint8Array(byteNumbers);
              
              // Add to zip without additional processing
              mainZip.file(`${location}/${item.fileName}`, byteArray, {
                binary: true
              });
            } catch (error) {
              console.error(`Error processing file for ${location}:`, error);
              toast.error(`Error processing file for ${location}`);
            }
          }
        }
      }
  
      // Generate and download the final zip file
      const content = await mainZip.generateAsync({ 
        type: "blob"
      });
  
      saveAs(content, `${selectedTableName}_Patienten_ohne_Leistung_${selectedDate}.zip`);
  
      // const response = await apiDataVisualization.post(
      //   `${DASHBOARD_BASE_URL}/api/get-table`,
      //   {
      //     tableName: `${selectedTableName}_Patienten_Ohne_Leistung`,
      //     date: selectedDate,
      //   },
      //   {
      //     headers: {
      //       "x-access-token": token,
      //     },
      //   }
      // );
      // const rawData = JSON.parse(response.data);
      // const zip = new JSZip();

      // Object.keys(rawData).forEach(function (key, index) {
      //   exportToExcel(rawData[key], key, zip);
      // });
      // zip.generateAsync({ type: "blob" }).then(function (content) {
      //   saveAs(content, `${selectedTableName}_Patienten_Ohne_Leistung_${selectedDate}.zip`);
      // });
    } catch (err) {
      console.error("Error exporting data:", err);
      toast.error("Error exporting data");
    }
    setExporting(false);
  };

  const exportData = (tableName, setExporting) => {
    switch (tableName) {
      case "No Follow Up":
      case "Patienten Ohne Folgetermin":
        handleExportNoFollowupTreatment(setExporting);
        break;
      case "No Documentation":
      case "Fehlerhafte Doku": {
        handleExportNoDocumentation(setExporting);
        break;
      }
      case "Patients without service":
      case "Patienten ohne Leistung": {
        handleExportPatientWithNoService(setExporting);
        break;
      }
      default: {
        toast.error("Please select correct option");
      }
    }
  };

  return (
    <div className="w-full flex justify-center">
      <div className="max-w-4xl w-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-xl text-black dark:text-white">
            {t("Data Visualization")}
          </h3>
        </div>
        <form action="#">
          <div className="p-6.5">
            <TableNameDropdown
              tableNames={tableNames}
              selectedTableName={selectedTableName}
              handleTableChange={handleTableChange}
            />
            <DateSelector
              handleDateChange={handleDateChange}
              dates={dates}
              loading={loading}
            />
            <button
              onClick={handleSubmit}
              className={`uppercase flex items-center justify-center w-full rounded p-3 font-medium text-gray text-sm ${
                submit
                  ? "bg-blue-600 cursor-not-allowed"
                  : "bg-primary hover:bg-opacity-90"
              }`}
              disabled={submit}
            >
              {submit ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
              ) : null}
              {submit ? t("Submitting...") : t("Submit")}
            </button>
          </div>
        </form>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full h-full p-0">
          <TableData
            selectedDate={selectedDate}
            tableData={tableData}
            exportData={exportData}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DataVisualization;
