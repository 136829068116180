import React from "react";
import { calculatePercentage } from "../utils";
import ExportDropdown from "./ExportDropdown";
import { useTranslation } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";

const TableData = ({ selectedDate, tableData, exportData }) => {
  const { t } = useTranslation();
  // Step 1: Identify the dataset with the most locations
  let maxLocations = new Set();

  tableData.forEach((gData) => {
    const tableName = Object.keys(gData)[0];
    const tableData = gData[tableName];
    const locations = tableData.map((data) => data.Location);

    // Add all locations to the Set
    locations.forEach((location) => maxLocations.add(location));
  });

  // Convert the Set to an array if needed
  maxLocations = Array.from(maxLocations);

  // Step 2: Create a map for easier location-based data access
  let dataMap = new Map();

  tableData.forEach((gData) => {
    const tableName = Object.keys(gData)[0];
    const tableData = gData[tableName];

    if (tableName === "DailyKPI_Patienten_Ohne_Leistung") {
      tableData.forEach((data) => {
        const location = data.Location;
        // const keys = Object.keys(data).filter((k) => k !== "Location");
        if (!dataMap.has(location)) {
          dataMap.set(location, {});
        }
        dataMap.get(location)["Patient_Ohne_Leistung"] = {
          value: parseInt(data["Patient_Ohne_Leistung"]),
          percentage: calculatePercentage(
            data["Patient_Ohne_Leistung"],
            data["Appointment"]
          ),
        };
        dataMap.get(location)["Patienten_No_Show"] = {
          value: parseInt(data["Patienten_No_Show"]),
          percentage: calculatePercentage(
            data["Patienten_No_Show"],
            data["Appointment"]
          ),
        };
      });
    } else {
      tableData.forEach((data) => {
        const location = data.Location;
        const key = Object.keys(data).find((k) => k !== "Location");
        if(tableName === 'Foxi') {
          if (!dataMap.has(location)) {
            dataMap.set(location, {});
          }
          dataMap.get(location)[tableName] = data[key];
        }else {
          const value = parseInt(data[key]);
          if (!dataMap.has(location)) {
            dataMap.set(location, {});
          }
          dataMap.get(location)[tableName] = value;
        }
       
      });
    }
  });

  dataMap = new Map([...dataMap.entries()].sort()); // sort the map

  // Step 3: Ensure all datasets have values for all locations
  maxLocations.forEach((location) => {
    tableData.forEach((gData) => {
      const tableName = Object.keys(gData)[0];
      if (!dataMap.get(location)[tableName]) {
        if (tableName === "DailyKPI_PatientId_With_Missing_Document") {
          dataMap.get(location)[tableName] = 0;
        } else {
          if (
            tableName === "DailyKPI_No_FollowUp_Treatment" &&
            dataMap.get(location)["DailyKPI_Appointment"] !== undefined
          ) {
            dataMap.get(location)[tableName] = 0;
          } else {
            dataMap.get(location)[tableName] =
              dataMap.get(location)[tableName] === 0 ? 0 : undefined;
          }
        }
      }
    });
  });

  // Step 4: Sort the locations based on a primary metric
  maxLocations.sort();
  const sortedLocation = maxLocations;
  // Step 7: Define German headers mapping
  const headers = {
    DailyKPI_Sales: {
      label: t("Performance"),
      description: t("Performance of the selected calendar day"),
    },
    Soll_Leistung: {
      label: t("Target performance"),
      description: t("Target / Planned value of daily performance for the selected calendar day"),
    },
    Leistung_letzte_13_Kalenderwochen: {
      label: t("Performance last 13 weeks"),
      description: t("Average of daily performances for the last 13 calendar weeks"),
    },
    Leistung_letzte_Woche: {
      label: t("Performance last week"),
      description: t("Sum of performance for the last 7 calendar days"),
    },
    Dentaleinheiten: {
      label: t("Dental units"),
      description: t("Number of dental units per medical care center (MVZ)"),
    },
    Performance_per_dental_unit: {
      label: t("Performance per dental unit"),
      description: t("For selected calendar day: Daily performance per dental unit"),
    },
    DailyKPI_Appointment: {
      label: t("Patient with appointment"),
      description: t("For selected calendar day: Number of patient appointments"),
    },
    DailyKPI_Patienten_Ohne_Termin: {
      label: t("Patients without an appointment"),
      description: t(
        "For selected calendar day: Number of patients who visited the practice without an appointment"
      ),
    },
    DailyKPI_Treatment: {
      label: t("Patients with performance"),
      description: t("For selected calendar day and based on patients with and without appointments: Number of patients with performance entries"),
    },
    Patient_Ohne_Leistung: {
      label: t("Patients without service"),
      description: t("For selected calendar day and based on patients with and without appointments: Number of patients for whom no performance entries were made (either patients did not show up or performance entry is still missing)"),
    },
    Foxi: {
      label: t("Foxi"),
      description: t('Foxi')
    },
    Patienten_No_Show: {
      label: t("Patient no show"),
      description: t(
        "For selected calendar day and based on patients with appointments: Number of patients who scheduled an appointment but did not attend"
      ),
    },
    DailyKPI_No_FollowUp_Treatment: {
      label: t("Patients without a follow-up appointment"),
      description: t(
        "For selected calendar day and based on patients with performance entries (i.e., no analysis for patients without performance entries or no-shows): Number of patients without a follow-up appointment"
      ),
    },
    DailyKPI_PatientId_With_Missing_Document: {
      label: t("Missing/incorrect documentation"),
      description: t("For selected calendar day and based on patients with and without performance entries: Number of patients with missing / incorrect documentation"),
    },
    DailyKPI_MVZ_Karten_fehlen: {
      label: t("Insurance cards are currently missing. Q"),
      description: t("Cumulative value for the current quarter: Number of missing insurance cards for patients with performance entries"),
    },
    DailyKPI_MVZ_Karten_ohne_Leistung: {
      label: t("Insurance cards without benefits current. Q"),
      description: t("Cumulative value for the current quarter: Number of patients whose insurance cards were scanned, but no performance entries were made"),
    },
  };

  const headerKeys = Object.keys(headers);
  const headerValues = Object.values(headers).map((header) => header.label);

  const headerDescription = Object.values(headers).map(
    (header) => header.description
  );

  // Calculate totals for each column
  const totals = {};
  headerKeys.forEach((key, index) => {
    totals[headerValues[index]] = 0;
    if (key === "DailyKPI_Sales") {
      dataMap.forEach((value) => {
        totals[headerValues[index]] +=
          value[key] !== undefined ? parseInt(value[key]) : 0;
      });
      totals[headerValues[index]] = totals[headerValues[index]].toFixed(2);
    } else if (key === "Patient_Ohne_Leistung" || key === "Patienten_No_Show") {
      dataMap.forEach((value) => {
        totals[headerValues[index]] +=
          value[key] !== undefined ? parseInt(value[key].value) : 0;
      });
    } else if (key === "Performance_per_dental_unit") {
      totals[headerValues[index]] = Math.round(
        parseInt(totals["Leistung pro Tag"]) / parseInt(totals["Dental-<br />einheiten"])
      );
    } else {
      dataMap.forEach((value) => {
        totals[headerValues[index]] +=
          value[key] !== undefined ? parseInt(value[key]) : 0;
      });
    }
  });

  return (
    <div>
      <div className="flex flex-col z-[10000] md:flex-row items-center justify-between gap-3 w-full sticky top-0">
        <h4 className="mb-3 text-lg text-left font-bold text-black dark:text-white z-10">
          {t("Date")}: {selectedDate}
        </h4>
        <ExportDropdown exportData={exportData} />
      </div>
      <div className="overflow-x-auto max-w-full max-h-[calc(100vh-200px)]">
        <div className="min-w-max relative">
          <table className="w-full top-0 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark relative">
            <thead className="dark:text-bodydark sticky top-0 z-10">
              <tr className="dark:bg-boxdark bg-[#F6F9FC]">
                <th className="text-left px-4 py-4 text-lg font-bold xsm:text-base md:sticky md:left-0 bg-[#F6F9FC] dark:bg-boxdark z-20 border-r border-stroke dark:border-strokedark">
                  {t("Location")}
                </th>
                {headerValues.map((header, index) => (
                  <th
                    key={index}
                    className="text-left px-4 py-2 text-lg font-bold xsm:text-base border-l border-stroke dark:border-strokedark"
                  >
                    <div className="flex items-center justify-center">
                      <div className="mr-6">
                        {header.split("<br />").map((part, index) => (
                          <span key={index}>
                            {part}
                            {index !== header.split("<br />").length - 1 && (
                              <br />
                            )}
                          </span>
                        ))}

                        <p className="text-left mt-1 text-sm text-gray-500">
                          Gesamt:{" "}
                          {totals[header] !== undefined
                            ? header === "Leistung pro Tag" ||
                              header === "Soll-Leistung pro Tag" ||
                              header === "Leistung pro Tag<br />letzte Woche" ||
                              header === "Durchschnitt der täglichen<br /> Leistungen der letzten 13 Wochen (Werktage)" ||
                              header === "Durchschnitt der täglichen<br /> Leistungen der letzten Woche (Werktage)"
                              ? parseInt(totals[header])
                                  .toString()
                                  .replace(".", ",")
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                              : parseInt(totals[header])
                                  .toString()
                                  .replace(".", ",")
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            : " "}
                        </p>
                      </div>
                      <div>
                        {/* 
                        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 bg-gray-800 text-white text-sm rounded-md px-3 py-1 opacity-0 group-hover:opacity-100 transition-opacity z-10">
                          {headerDescription[index]}
                        </div> */}

                        <svg
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={headerDescription[index]}
                          className="fill-current hover:cursor-pointer"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="2.5"
                            fill="none"
                          />
                          <path
                            d="M11 10H13V17H11V10ZM11 7H13V9H11V7Z"
                            fill="currentColor"
                          />
                        </svg>
                        <ReactTooltip
                          style={{zIndex: 999}}
                          id="my-tooltip"
                        />
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-boxdark">
              {sortedLocation.map((label, index) => (
                <tr
                  key={index}
                  className={`text-left ${
                    index === sortedLocation.length - 1
                      ? ""
                      : "border-b border-stroke dark:border-strokedark"
                  }`}
                >
                  <td className="text-left text-black dark:text-white px-4 py-4 md:sticky md:left-0 bg-white dark:bg-boxdark border-r border-stroke dark:border-strokedark">
                    {label}
                  </td>
                  {headerKeys.map((key, idx) => (
                    <td
                      key={idx}
                      className="text-left text-black dark:text-white px-4 py-2 border-l border-stroke dark:border-strokedark"
                    >
                      {dataMap.get(label)[key] !== undefined ? (
                        typeof dataMap.get(label)[key] === "object" ? (
                          <>
                            <span>{dataMap.get(label)[key].value}</span>
                            <span className="text-gray-500">
                              -({dataMap.get(label)[key].percentage})
                            </span>
                          </>
                        ) : key === "DailyKPI_Sales" ||
                          key === "Soll_Leistung" ||
                          key === "Leistung_letzte_Woche" ||
                          key === "Leistung_letzte_13_Kalenderwochen" ||
                          key === "Performance_per_dental_unit" ? (
                          dataMap
                            .get(label)
                            [key].toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                        ) : (
                          dataMap
                            .get(label)
                            [key].toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        )
                      ) : (
                        " "
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableData;
